<template>
  <div class="body">
    <div id="book">
      <div class="book-cover one-page" @transitionend="turnOver($event)" @click="turn(0)"></div>
      <div class="book-page one-page" @transitionstart="turnStart($event)" @transitionend="turnOver($event)"
           :key="index"
           :style="{'z-index': 1000 - index - 1}"
           @click="turn(index + 1)" v-for="(item, index) in contentList">
        <div class="page-front">
          {{ item.content }}
        </div>
        <div class="page-back">
          {{ item.content }} 背面内容 - 下一页的文字
        </div>
      </div>
      <div class="book-background one-page" @transitionend="turnOver($event)" @click="turn(11)"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    let pages;
    let cover;
    let btn;
    let book;
    let allPages;
    return {
      pageCount: 0,
      pageNo: 0,
      isForward: false,
      pages,
      cover,
      btn,
      book,
      allPages,
      turnPageStatus: false,
      contentList: [
        {
          "content": "111111111111111",
        },
        {
          "content": "22222222222222222",
        },
        {
          "content": "33333333333333333",
        },
        {
          "content": "444444444444444444",
        },
        {
          "content": "55555555555555555",
        },
        {
          "content": "666666666666666666",
        },
        {
          "content": "7777777777777777777",
        },
        {
          "content": "888888888888888888",
        },
        {
          "content": "99999999999999999",
        },
        {
          "content": "aaaaaaaaaaaaaaaaa",
        },
      ]
    }
  },
  methods: {
    turn(index) {
      if (this.turnPageStatus) {
        return;
      }
      this.turnPageStatus = true;
      index >= this.pageNo ? this.nextPage(index) : this.prePage(index)
    },
    prePage(index) {
      this.isForward = true;
      this.pageNo--
      if ((this.pageCount - 1) === this.pageNo || this.pageCount === this.pageNo) {
        this.allPages[this.pageNo].style.transform = 'rotateY(0deg)'
        this.book.style.transform = 'translateX(21vw)'
      } else {
        this.allPages[this.pageNo].style.transform = 'rotateY(0deg)'
      }
      if (0 === index) {
        this.book.style.transform = 'translateX(0px)'
      }
      if (index > 0 && index < this.pageCount) {
        let width = window.getComputedStyle(this.allPages[this.pageNo]).width;
        width = parseFloat(width.replace('px', ''));
        this.allPages[this.pageNo].style.width = (width + this.pageNo / 2) + 'px';
      }
      this.allPages[this.pageNo].style.zIndex = 10000 + (this.pageCount - this.pageNo)
    },
    nextPage(index) {
      this.isForward = false;
      if (0 === this.pageNo) {
        this.allPages[this.pageNo].style.transform = 'rotateY(-180deg)'
        this.book.style.transform = 'translateX(21vw)'
      } else {
        this.allPages[this.pageNo].style.transform = 'rotateY(-180deg)'
      }
      if (index === this.pageCount) {
        this.book.style.transform = 'translateX(42vw)'
      }
      this.allPages[this.pageNo].style.zIndex = 10000 + this.pageNo
      if (index > 0 && index < this.pageCount) {
        let width = window.getComputedStyle(this.allPages[this.pageNo]).width;
        width = parseFloat(width.replace('px', ''));
        this.allPages[this.pageNo].style.width = (width - this.pageNo / 2) + 'px';
      }
      this.pageNo++
    },
    turnOver: function (event) {
      if (event && event.propertyName !== 'transform') {
        return
      }
      this.turnPageStatus = false;
    },
    turnStart(event) {
      if (event && event.propertyName !== 'transform') {
        return
      }
      if (this.isForward) {
        let currentPage = this.allPages[this.pageNo];
        let frontElement = currentPage.querySelector('.page-front');
        let backElement = currentPage.querySelector('.page-back');
        backElement.style.visibility = 'hidden';
        backElement.style.opacity = 0;
        backElement.style.height = 0;
        backElement.style.padding = 0;
        frontElement.style.visibility = 'visible';
        frontElement.style.opacity = 1;
        frontElement.style.padding = '5px';
      } else {
        let currentPage = this.allPages[this.pageNo - 1];
        let frontElement = currentPage.querySelector('.page-front');
        let backElement = currentPage.querySelector('.page-back');
        frontElement.style.visibility = 'hidden';
        frontElement.style.opacity = 0;
        frontElement.style.height = 0;
        frontElement.style.padding = 0;
        backElement.style.visibility = 'visible';
        backElement.style.opacity = 1;
        backElement.style.padding = '5px';
      }
    }
  },
  mounted: function () {
    this.pages = document.querySelectorAll('.book-page')
    this.cover = document.querySelectorAll('.book-cover')
    this.book = document.querySelector('#book')
    this.allPages = document.querySelectorAll('.one-page')
    this.pageCount = this.contentList.length + 1
  }
}
</script>

<style scoped>
.body {
  margin: 0;
  padding: 0;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
}

#book {
  cursor: pointer;
  position: relative;
  height: calc(94% + 3%);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
  perspective: 1800px;
  transform-style: preserve-3d;
}

.book-cover {
  cursor: pointer;
  height: calc(94% + 3%);
  width: calc(40vw + 2vw);
  position: absolute;
  background-color: #114f4f;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: solid 1px #fff;
  transition: .5s;
  transform-origin: left;
  backface-visibility: visible;
  z-index: 1000;
}

.book-background {
  cursor: pointer;
  height: calc(94% + 3%);
  width: calc(40vw + 2vw);
  position: absolute;
  background-color: #114f4f;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: .5s;
  transform-origin: left;
  backface-visibility: visible;
  z-index: -1;
}

.book-page {
  cursor: pointer;
  height: 94%;
  width: 40vw;
  position: absolute;
  transition: .5s;
  transform-origin: -1vw;
  background-size: cover;
  backface-visibility: visible;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  text-overflow: ellipsis;
}

.page-back {
  visibility: hidden;
  transform: rotateY(180deg) translateZ(1px);
  opacity: 0;
}

.page-front, .page-back {
  word-wrap: break-word;
  padding: 5px;
  transition: opacity 0.8s ease;
}
</style>
